import React, { useState, useEffect, useMemo } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import Box from '@mui/material/Box';
import Papa from 'papaparse';
import MediaCardArea from './MediaCardArea';

const controlLimits = {
  danger: 80,
  warning: 60,
  caution: 40,
  attention: 20,
  lower: 0,
};

export default function MediaCard() {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    Papa.parse('/csvs/T2_df.csv', {
      download: true,
      header: true,
      complete: (result) => {
        setData(result.data);
        setCurrentIndex(Math.floor(Math.random() * (result.data.length - 10)));
      },
    });

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [data.length]);

  const displayData = useMemo(() => {
    return data.slice(currentIndex, currentIndex + 10).map((row) => ({
      name: row.datetime,
      value: parseFloat(row.T2),
    }));
  }, [data, currentIndex]);

  const fireRiskIndex = displayData.length > 0 ? displayData[displayData.length - 1].value : 0;

  return (
    <Card sx={{ maxWidth: 900, margin: 'auto' }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          종합화재지수
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ width: 500}}>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={displayData}>
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
                <ReferenceLine y={controlLimits.danger} label="심각" stroke="red" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.warning} label="경계" stroke="orange" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.caution} label="주의" stroke="yellow" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.attention} label="관심" stroke="green" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.lower} label="안정" stroke="blue" strokeDasharray="3 3" />
                <Tooltip />
                <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </Box>
          <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ bgcolor: '#40444F', p: 2, borderRadius: 2 }}>
              <Typography variant="h6" component="div">
                현재: 안정
              </Typography>
              <Typography variant="h4" component="div" color="blue">
                {fireRiskIndex.toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <MediaCardArea latestValue={fireRiskIndex.toFixed(2)} />
      </CardContent>
    </Card>
  );
}