import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const floors = ['1호기', '2호기', '지원설비'];

const generateRandomValue = (baseValue) => {
  const base = parseFloat(baseValue);
  return (base + (Math.random() * 2.5 + 0.5)).toFixed(2);
};

export default function MediaCardArea({ latestValue }) {
  const data = React.useMemo(() => {
    return {
      '1호기': latestValue,
      '2호기': generateRandomValue(latestValue),
      '지원설비': generateRandomValue(latestValue),
    };
  }, [latestValue]);

  return (
    <Card sx={{ maxWidth: 900, margin: 'auto' }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          공간별 화재위험지수
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <TableContainer component={Paper} sx={{ width: '80%' }}>
            <Table size="small" aria-label="fire risk index by floor">
              <TableHead>
                <TableRow>
                  <TableCell>위험지수</TableCell>
                  <TableCell align="right">1호기</TableCell>
                  <TableCell align="right">2호기</TableCell>
                  <TableCell align="right">지원설비</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">Latest Value</TableCell>
                  <TableCell align="right">{data['1호기']}</TableCell>
                  <TableCell align="right">{data['2호기']}</TableCell>
                  <TableCell align="right">{data['지원설비']}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>
    </Card>
  );
}
